import { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, MenuItem, MenuList, paperClasses, Popover, Typography } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';

import { getGenerativeText, rateGenerativeText } from 'Lib/api/ai';
import GenerativeFunctions from 'Lib/constants/ai/generativeFunctions';
import Tones from 'Lib/constants/ai/tones';
import useApi from 'Lib/hooks/useApi';
import isEmpty from 'Lib/utilities/isEmpty';

import FloatingButton from '../utilities/FloatingButton';

const Generative = ({ field, position, onClose, setValue }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [functionAnchorEl, setFunctionAnchorEl] = useState(null);
    const [generativeAnchorEl, setGenerativeAnchorEl] = useState(null);
    const [selectedFunction, setSelectedFunction] = useState({});
    const [selectedText, setSelectedText] = useState('');
    const [generatedContent, setGeneratedContent] = useState({});

    const fetchGenerativeContent = useApi(getGenerativeText);
    const submitFeedback = useApi(rateGenerativeText);

    const allowedGenerativeFunctions = GenerativeFunctions.getOptions().filter(option => option.value !== GenerativeFunctions.Generate);

    useEffect(() => {
        if (selectedFunction.value) {
            handleGenerate();
        }
    }, [selectedFunction]);

    const handleGenerate = () => {
        setIsGenerating(true);

        if (selectedFunction.isToneFunction) {
            fetchGenerativeContent({ text: selectedText, toneID: selectedFunction.value }).then(response => {
                setGeneratedContent(response.data);
                setIsGenerating(false);
            });
        } else {
            fetchGenerativeContent({ text: selectedText, functionID: selectedFunction.value }).then(response => {
                setGeneratedContent(response.data);
                setIsGenerating(false);
            });
        }
    };

    const handlePositiveFeedback = () => {
        submitFeedback(generatedContent.aiStatisticID, { wasHelpful: true });
    };

    const handleNegativeFeedback = () => {
        submitFeedback(generatedContent.aiStatisticID, { wasHelpful: false });
    };

    const handleInsert = () => {
        if (isEmpty(field.value)) {
            setValue(generatedContent.response);
        } else {
            setValue(field.value.replace(selectedText, generatedContent.response));
        }

        handleClose();
    };

    const handleFunctionPopoverClick = event => {
        setFunctionAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setFunctionAnchorEl(null);
        setGenerativeAnchorEl(null);
        // Resets position to an empty object which controls the rendering of the FloatingButton
        onClose();
    };

    const handleGenerativeFunction = generativeFunction => {
        setGenerativeAnchorEl(functionAnchorEl);
        setSelectedText(window.getSelection().toString());
        setSelectedFunction(generativeFunction);
    };

    return (
        <>
            {/* Floating Button */}
            {!isEmpty(position) && <FloatingButton onClick={handleFunctionPopoverClick} position={position} />}

            {/* Function Popover */}
            <Popover open={Boolean(functionAnchorEl)} anchorEl={functionAnchorEl} onClose={handleClose}>
                <MenuList>
                    {allowedGenerativeFunctions.map(option => (
                        <MenuItem key={option.value} onClick={() => handleGenerativeFunction({ ...option, isToneFunction: false })}>
                            {option.label}
                        </MenuItem>
                    ))}
                    <NestedMenuItem rightIcon={<ChevronRightIcon />} label="Tone" parentMenuOpen={Boolean(functionAnchorEl)} sx={{ px: 2 }}>
                        {Tones.getOptions().map(option => (
                            <MenuItem key={option.value} onClick={() => handleGenerativeFunction({ ...option, isToneFunction: true })}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </NestedMenuItem>
                </MenuList>
            </Popover>

            {/* Generated Content Popover */}
            <Popover open={Boolean(generativeAnchorEl)} anchorEl={generativeAnchorEl} onClose={handleClose} sx={{ [`& .${paperClasses.root}`]: { minWidth: 350 } }}>
                <Card
                    onClick={event => {
                        // There's some strange event bubbling going on where clicking the card is causing the anchor element to become invalid.
                        // This is a workaround to prevent that from happening, it's most likely a rendering race condition of some sort
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <CardHeader title={selectedFunction.label} />
                    <CardContent sx={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', textAlign: isGenerating ? 'center' : 'inherit' }}>
                        {isGenerating ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {generatedContent.response}
                            </Typography>
                        )}
                    </CardContent>
                    {isGenerating ? null : (
                        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <IconButton size="small" onClick={handlePositiveFeedback}>
                                    <ThumbUpAltIcon color="info" fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={handleNegativeFeedback}>
                                    <ThumbDownAltIcon color="info" fontSize="small" />
                                </IconButton>
                            </Box>
                            <Box sx={{ justifyContent: 'flex-end' }}>
                                <Button size="small" variant="outlined" onClick={handleGenerate} sx={{ mr: 2 }}>
                                    Regenerate
                                </Button>
                                <Button size="small" variant="outlined" onClick={handleInsert}>
                                    Insert
                                </Button>
                            </Box>
                        </CardActions>
                    )}
                </Card>
            </Popover>
        </>
    );
};

export default Generative;
