import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Fab } from '@mui/material';

const FloatingButton = ({ position, onClick }) => {
    return (
        <Fab
            onClick={onClick}
            color="primary"
            size="small"
            sx={{
                position: 'fixed',
                top: position.top,
                left: position.left
            }}
        >
            <TipsAndUpdatesOutlinedIcon />
        </Fab>
    );
};

export default FloatingButton;
